import React from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './home.css';

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <span>佛經誦讀</span>
            <a href="https://apps.apple.com/tw/app/%E4%BD%9B%E7%B6%93%E8%AA%A6%E8%AE%80/id1559488729" style={{ margin: "5px" }}><img style={{ width: "125px" }} src="assets/appstore_w180.png" alt="App Store"></img></a>
            <a href="https://play.google.com/store/apps/details?id=tw.mingyan.sutra" style={{ margin: "5px" }}><img style={{ width: "125px" }} src="assets/google_play_icon_w180.png" alt="Google Play"></img></a>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="help">
          <IonCard>
            <table style={{ height: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <picture>
                      <source srcSet="assets/help/orientation2.svg" media="(prefers-color-scheme: dark)" />
                      <img src="assets/help/orientation.svg" style={{ width: "100%" }} alt=""></img>
                    </picture>
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "middle", height: "25%" }}>
                    <IonCardHeader>
                      <IonCardTitle>您可以依照裝置狀況，直置使用本程式，也可以翻轉橫置使用。</IonCardTitle>
                    </IonCardHeader>
                  </td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </table>
          </IonCard>
        </div>

        <div className="help">
          <IonCard>
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <picture>
                      <source srcSet="assets/help/device2.svg" media="(prefers-color-scheme: dark)" />
                      <img src="assets/help/device.svg" style={{ width: "100%" }} alt=""></img>
                    </picture>
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "middle", height: "25%" }}>
                    <IonCardHeader>
                      <IonCardTitle>平板與手機皆適合使用，手機建議直置使用，平板建議橫置使用。</IonCardTitle>
                    </IonCardHeader>
                  </td>
                </tr>
              </tbody>
            </table>
          </IonCard>
        </div>
        <div className="help">
          <IonCard>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "53%", verticalAlign: "middle" }}>
                    <picture>
                      <source srcSet="assets/help/swipe2.svg" media="(prefers-color-scheme: dark)" />
                      <img src="assets/help/swipe.svg" style={{ width: "100%" }} alt=""></img>
                    </picture>
                  </td>
                  <td>
                    <IonCardHeader style={{ width: "100%" }}>
                      <IonCardTitle>透過左右滑動可切換前後頁面，由左至右滑動可切換至下一頁，由右至左滑動可切換至上一頁。</IonCardTitle>
                    </IonCardHeader></td>
                </tr>
              </tbody>
            </table>
          </IonCard>
        </div>
        <div className="help">
          <IonCard>
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <picture>
                      <source srcSet="assets/help/pace2.svg" media="(prefers-color-scheme: dark)" />
                      <img src="assets/help/pace.svg" style={{ width: "93%" }} alt=""></img>
                    </picture>
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "middle", height: "25%" }}>
                    <IonCardHeader>
                      <IonCardTitle>使用時系統會自動與經文同步，且唱誦到的文字會強調顯示，若有需求亦可點選文字可直接跳至該段播放。</IonCardTitle>
                    </IonCardHeader>
                  </td>
                </tr>
              </tbody>
            </table>
          </IonCard>
        </div>
        <div className="help">
          <IonCard>
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <picture>
                      <source srcSet="assets/help/preview2.svg" media="(prefers-color-scheme: dark)" />
                      <img src="assets/help/preview.svg" style={{ width: "100%" }} alt=""></img>
                    </picture>
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "middle", height: "25%" }}>
                    <IonCardHeader>
                      <IonCardTitle>每頁的最末行灰色文字為次頁第一行，系統念誦到此行系統將自動翻頁，免除傳統紙本翻頁及找不到經咒文字的困擾。</IonCardTitle>
                    </IonCardHeader>
                  </td>
                </tr>
              </tbody>
            </table>
          </IonCard>
        </div>
        <div className="help">
          <IonCard>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "55%", verticalAlign: "middle" }}>
                    <picture>
                      <source srcSet="assets/help/light2.svg" media="(prefers-color-scheme: dark)" />
                      <img src="assets/help/light.svg" style={{ width: "100%" }} alt=""></img>
                    </picture>
                  </td>
                  <td>
                    <IonCardHeader style={{ width: "100%" }}>
                      <IonCardTitle>經咒播放過程之中，螢幕將保持喚醒，使畫面持續顯示，以利於念誦使用。於念誦完畢後回歸系統預設。</IonCardTitle>
                    </IonCardHeader></td>
                </tr>
              </tbody>
            </table>
          </IonCard>
        </div>
        <div className="help">
          <IonCard>
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <picture>
                      <source srcSet="assets/help/menu2.svg" media="(prefers-color-scheme: dark)" />
                      <img src="assets/help/menu.svg" style={{ width: "100%" }} alt=""></img>
                    </picture>
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "middle", height: "12%" }}>
                    <IonCardHeader>
                      <IonCardTitle>點選下方蓮花圖示可開啟功能設定選單。</IonCardTitle>
                    </IonCardHeader>
                  </td>
                </tr>
              </tbody>
            </table>
          </IonCard>
        </div>
        <div className="help">
          <IonCard>
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <picture>
                      <source srcSet="assets/help/play2.svg" media="(prefers-color-scheme: dark)" />
                      <img src="assets/help/play.svg" style={{ width: "100%" }} alt=""></img>
                    </picture>
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "middle", height: "25%" }}>
                    <IonCardHeader>
                      <IonCardTitle>透過設定選單的「暫停播放」/「繼續播放」可以得知目前的播放狀況並進行操作，於下次啟動程式後會自動套用此操作。</IonCardTitle>
                    </IonCardHeader>
                  </td>
                </tr>
              </tbody>
            </table>
          </IonCard>
        </div>
        <div className="help">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: "bottom" }}>
                        <picture>
                          <source srcSet="assets/help/gear2.svg" media="(prefers-color-scheme: dark)" />
                          <img style={{ width: "2.5em" }} src="assets\menu\gear.svg" alt=""></img>
                        </picture>
                      </td>
                      <td style={{ verticalAlign: "bottom", fontSize: "150%" }}>
                        &nbsp;設定
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>字體大小</div>
                  <div>經文內容支援字體明體、正楷體、正宋體、魏碑體顯示。</div>
                  <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>選擇字型</div>
                  <div>在不同裝置上預設最適合的文字大小，可透過設定調整字級大小。</div>
                  <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>顏色樣式</div>
                  <div>可設定經文顏色配置。</div>
                  <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>顯示預覽列</div>
                  <div>開啟或關閉經文最後一列之預覽行。</div>
                </div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </div>
        <div className="help">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: "bottom" }}>
                        <picture>
                          <source srcSet="assets/help/fast2.svg" media="(prefers-color-scheme: dark)" />
                          <img style={{ width: "2.5em" }} src="assets\menu\fast.svg" alt=""></img>
                        </picture>
                      </td>
                      <td style={{ verticalAlign: "bottom", fontSize: "150%" }}>
                        &nbsp;播放速率
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>播放速率</div>
                  <div>調整經文唱誦之速度。</div>
                  <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>重複播放</div>
                  <div>經文唱誦後自動重複播放。</div>
                  <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>自動回復進度</div>
                  <div>勾選「自動回復進度」，下次使用本程式時將會回復至上次的進度點。</div>
                </div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </div>
        <div className="help">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: "bottom" }}>
                        <picture>
                          <source srcSet="assets/help/book2.svg" media="(prefers-color-scheme: dark)" />
                          <img style={{ width: "2.5em" }} src="assets\menu\book.svg" alt=""></img>
                        </picture>
                      </td>
                      <td style={{ verticalAlign: "bottom", fontSize: "150%" }}>
                        &nbsp;章卷頁面
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>選擇頁數</div>
                  <div>可跳至選取之頁數。</div>
                  <div style={{ marginTop: "0.5em", fontWeight: "bold" }}>選擇章節</div>
                  <div>可跳至選取之章節。</div>
                </div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default Home;
